
import { defineComponent, ref } from 'vue'
import Image from 'primevue/image'
import Button from 'primevue/button'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    Button,
    Image
  },
  props: {
    editFormOfferData: {
      type: Object
    }
  },
  setup (props, { emit }) {
    const dateFormat = (date: any) => {
      let formatedDate = null
      if (date !== null) {
        formatedDate = new Date(date).toLocaleDateString('es-AR')
      }
      return formatedDate
    }

    const router = useRouter()
    const back = () => { router.push('/job-bank/job-offers/main') }
    const unPublish = () => {
      emit('handleStatusChange', { _id: props.editFormOfferData?._id })
    }

    return {
      back,
      dateFormat,
      unPublish
    }
  }
})
